<template>
  <div class="games-wrapper the-box">
    <div class="box-label pattern-bg">{{ getGamesPageContent.trYourPrediction }}</div>
    <div v-if="gamesListReady" class="games pad-1">
      <div class="stage-nav">
        <ChevronIcon class="chevron-in-select"/>
        <select class="input input--select" v-model="eventGroupInView">
          <option
          :disabled="eventGroup.matches === undefined"
          v-for="(eventGroup, index) in gamesData.events"
          :key="index"
          :value="index">{{ eventGroup.label }}
          </option>
        </select>
      </div>
      <div v-if="gamesDataPayload[eventGroupInView] !== 0 && gamesDataPayload[eventGroupInView].matchCount !== gamesDataPayload[eventGroupInView].matchesAvailable"
      class="match-availability-info"
      >
        {{ getGamesPageContent.trRemainingMatches }}: <span>{{ gamesDataPayload[eventGroupInView].matchesAvailable }} / {{ gamesDataPayload[eventGroupInView].matchCount }}</span>
      </div>
      <div v-if="gamesDataPayload.length > 0" class="stage-wrapper">
        <form @submit.prevent="modal = true" class="stage-games-form">
          <div class="stage-games" :class="{ busy: submitting }">
            <div v-for="match in gamesDataPayload[eventGroupInView].matches"
            :key="match.matchId"
            class="stage-game"
            :class="{
              started: match.started,
              right: (match.home.finalScore === match.home.score && match.away.finalScore === match.away.score) && match.userMadePick,
              wrong: (match.home.finalScore !== undefined
              && match.away.finalScore !== undefined)
              && (match.home.finalScore !== match.home.score || match.away.finalScore !== match.away.score)
              && match.userMadePick,
            }">
              <div v-if="match.started" class="match-date">
                {{ match.home.finalScore !== undefined ? getGamesPageContent.trMatchFinished : getGamesPageContent.trMatchStarted }}
                <div class="prediction-status"
                v-if="(match.home.finalScore !== undefined && match.away.finalScore !== undefined)
                && match.userMadePick === true
                && (match.home.finalScore === match.home.score && match.away.finalScore === match.away.score)"
                >
                  +20 {{ $store.state.globalContent.trPoints }}
                </div>
              </div>
              <div v-if="match.home.finalScore === undefined && match.started === false" class="match-date">{{ matchDate(match.startDate) }}</div>
              <div v-if="gamesDataPayload[eventGroupInView].disabled && match.userMadePick"
              class="user-prediction"
              :class="{ big: match.started }"
              >
                {{ getGamesPageContent.trYourPrediction }}
              </div>
              <div class="match-teams">
                <div class="match-team">
                  <div class="match-team__name">{{ match.home.teamName.substring(0, 3) }}</div>
                  <div class="match-team__flag">
                    <img :src="match.home.logo" :alt="match.home.teamName">
                  </div>
                </div>
                <div class="match-score"
                :class="{ disabled: gamesDataPayload[eventGroupInView].disabled || match.started, 'started-no-pick':  match.started && !match.userMadePick}"
                >
                  <div v-if="match.started && !match.userMadePick" class="no-user-pick-made">
                    - : -
                  </div>
                  <div class="input--score-wrapper">
                    <span class="score-overlay">{{ match.home.score }}</span>
                    <select class="input input--score" v-model.number="match.home.score">
                      <option :value="0">0</option>
                      <option :value="1">1</option>
                      <option :value="2">2</option>
                      <option :value="3">3</option>
                      <option :value="4">4</option>
                      <option :value="5">5</option>
                      <option :value="6">6</option>
                      <option :value="7">7</option>
                      <option :value="8">8</option>
                      <option :value="9">9</option>
                      <option :value="10">10</option>
                    </select>
                  </div>
                  <div class="input--score-wrapper">
                    <span class="score-overlay">{{ match.away.score }}</span>
                    <select class="input input--score" v-model.number="match.away.score">
                      <option :value="0">0</option>
                      <option :value="1">1</option>
                      <option :value="2">2</option>
                      <option :value="3">3</option>
                      <option :value="4">4</option>
                      <option :value="5">5</option>
                      <option :value="6">6</option>
                      <option :value="7">7</option>
                      <option :value="8">8</option>
                      <option :value="9">9</option>
                      <option :value="10">10</option>
                    </select>
                  </div>
                </div>
                <div class="match-team">
                  <div class="match-team__flag">
                    <img :src="match.away.logo" :alt="match.away.teamName">
                  </div>
                  <div class="match-team__name">{{ match.away.teamName.substring(0, 3) }}</div>
                </div>
              </div>
              <div class="match-final-score" :class="{ in: match.home.finalScore !== undefined && match.away.finalScore !== undefined }">
                <div class="final-score-label">{{ getGamesPageContent.trFinalScore }}</div>
                <div class="final-score">
                  {{ match.home.finalScore !== undefined ? match.home.finalScore : '-' }}
                  :
                  {{ match.away.finalScore !== undefined ? match.away.finalScore : '-' }}
                </div>
              </div>
            </div>
          </div>
          <div class="submit-warning-box">
            <div class="submit-warning content" v-html="getGamesPageContent.trConfMessage1"></div>
            <div class="games-submit-wrapper">
              <div v-if="submitting" class="lds-dual-ring button-loading"></div>
              <button
              ref="picks-submit"
              class="button button--main games-submit-button full"
              :class="{ disabled: submitting }"
              :disabled="submissionLocked || nothingToSubmit || isOldStage"
              type="submit">
              <LockIcon class="lock-icon-button" v-if="submissionLockedTimer > 0 && nothingToSubmit === false && !isOldStage"/>
              {{ (nothingToSubmit || isOldStage) ? getGamesPageContent.trAwait : getGamesPageContent.trSubmitPicks }}
              <span v-if="submissionLockedTimer > 0 && nothingToSubmit === false && !isOldStage">({{ submissionLockedTimer }})</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <transition name="fade">
        <Modal @modalAccepted="acceptModal($event)" v-if="modal" :btnText="getGamesPageContent.trFinalButton">
          <div style="margin-bottom: .5rem;" v-html="getGamesPageContent.trConfMessage2"></div>
        </Modal>
      </transition>
      <transition name="fade">
        <Modal @modalAccepted="closePicksModal" v-if="picksModalOverlay && getPicksOverlay.type && (getPicksOverlay.url || getPicksOverlay.iframe)"
        type="partners" :iframe="getPicksOverlay.type === 'iframe'">
          <div v-if="getPicksOverlay.type === 'image' && getPicksOverlay.url" class="banner-overlay-body">
            <a :href="getPicksOverlay.url" target="_blank" rel="noindex nofollow noopener noreferrer">
              <img :src="getPicksOverlay.banner" alt="">
              <div v-if="$route.params.geo === 'de'" class="modal-disc">
                Der Anbieter befindet sich derzeit im Prozess der Beantragung einer deutschen Lizenz.
                Wir rechnen damit, dass die notwendige Genehmigung dem Anbieter in absehbarer Zeit gewährt wird
              </div>
            </a>
          </div>
          <div v-if="getPicksOverlay.type === 'iframe' && getPicksOverlay.iframe && getPicksOverlay.iframeSize" class="banner-overlay-body">
            <iframe class="overlay-iframe" scrolling="no"
            frameBorder="0"
            style="padding:0px; margin:0px; border:0px;border-style:none;border-style:none;"
            :width="getPicksOverlay.iframeSize.split('x')[0]"
            :height="getPicksOverlay.iframeSize.split('x')[1]"
            :src="getPicksOverlay.iframe"></iframe>
          </div>
        </Modal>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import LockIcon from '@/assets/images/lock.svg';
import ChevronIcon from '@/assets/images/chevron.svg';

export default {
  name: 'Games',
  components: {
    LockIcon,
    ChevronIcon,
    Modal,
  },
  props: {
    gamesData: {
      events: Array,
      committedPicks: Array,
      defaultEventIndex: Number,
      userPicks: String,
      competitionStarts: Boolean || Number,
    },
  },
  data() {
    return {
      eventGroupInView: this.gamesData.defaultEventIndex,
      gamesDataPayload: [],
      committedPicksActive: [],
      gamesListReady: false,
      submissionLocked: true,
      submissionLockedTimer: 5,
      polling: null,
      submitting: false,
      nothingToSubmit: false,
      tempSubmittedMatchIds: [],
      modal: false,
      picksModalOverlay: false,
    };
  },
  methods: {
    closePicksModal() {
      this.picksModalOverlay = false;
    },
    acceptModal(event) {
      if (!event) {
        this.modal = false;
        return;
      }
      this.submitPicks();
      this.modal = false;
    },
    matchDate(time) {
      return this.$moment(time * 1000).format('dddd, MMMM Do YYYY, H:mm');
    },
    submitPicks() {
      if (this.submissionLocked || this.submitting || this.nothingToSubmit || this.isOldStage) return;
      const payload = {
        tournament: this.$store.state.activeTournament,
        user: this.$store.state.user.email,
        userid: this.$store.state.user.id,
        type: 'matches',
        competitionId: this.gamesDataPayload[0].competitionId,
        eventIds: this.gamesDataPayload.map((event) => event.eventId),
        picks: [],
        geo: this.$route.params.geo,
      };

      const timestamp = Math.round(Date.now() / 1000);
      // Remove already commited picks from the payload
      if (this.committedPicksActive.length !== 0) {
        const resultEventIds = payload.eventIds.filter((eventId) => !this.committedPicksActive.includes(eventId));
        payload.eventIds = resultEventIds;
      }

      this.gamesDataPayload.forEach((element) => {
        if (element.disabled !== undefined) return;

        const eventGroup = {
          eventId: element.eventId,
          picks: [],
          timestamp,
        };
        console.log(timestamp);
        // Prevent submitting picks where final score exists or time has passsed - just in case someone gets creative
        const filterValidMatchPicks = element.matches.filter(
          (preValidatedMatch) => preValidatedMatch.home.finalScore === undefined && !preValidatedMatch.started && timestamp < preValidatedMatch.startDate,
        );

        this.tempSubmittedMatchIds = filterValidMatchPicks.map((match) => match.matchId);

        eventGroup.picks = filterValidMatchPicks.map((match) => {
          const entry = {
            matchId: match.matchId,
            home: {
              score: match.home.score,
              teamId: match.home.teamId,
            },
            away: {
              score: match.away.score,
              teamId: match.away.teamId,
            },
          };
          return entry;
        });

        payload.picks.push(eventGroup);
      });
      console.log(payload);
      this.savePicks(payload);
    },
    async savePicks(payload) {
      try {
        this.submitting = true;
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const { data } = await this.axios.post(`${process.env.VUE_APP_API}/picks/v1/save`, payload, config);
        this.submitting = false;
        if (data === 1) {
          const uniqueSetOfCommitedPicks = new Set([
            ...this.committedPicksActive,
            ...payload.eventIds,
          ]);
          this.committedPicksActive = Array.from(uniqueSetOfCommitedPicks);
          console.log(payload.eventIds);
          this.prepGamesList(true);
          document.getElementById('app').scrollIntoView();
          this.$emit('picksSubmitted');
          this.picksModalOverlay = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    prepGamesList(refresh = false) {
      if (refresh) this.gamesListReady = false;
      if (!refresh) {
        this.gamesDataPayload = this.gamesData.events.filter((e) => e.matches !== undefined);
        if (this.committedPicksActive.length === 0) {
          this.gamesListReady = true;
          return;
        }
      }
      const timestamp = Math.round(Date.now() / 1000);
      // Parse user picks
      let allUserPicks = [];

      if (!refresh && this.gamesData.userPicks.length > 0) allUserPicks = JSON.parse(this.gamesData.userPicks);

      let stagesNotAllowedForSubmissionCounter = 0;
      this.gamesDataPayload.forEach((element, index) => {
        if (this.committedPicksActive.includes(element.eventId)) {
          this.gamesDataPayload[index].disabled = true;
          stagesNotAllowedForSubmissionCounter += 1;
        }
        if (refresh && this.tempSubmittedMatchIds.length > 0) {
          this.gamesDataPayload[index].matches.forEach((match, matchIndex) => {
            this.gamesDataPayload[index].matches[matchIndex].started = timestamp > this.gamesDataPayload[index].matches[matchIndex].startDate;
            if (this.tempSubmittedMatchIds.includes(match.matchId)) {
              this.gamesDataPayload[index].matches[matchIndex].userMadePick = true;
            }
          });
        }
        if (allUserPicks.length > 0) {
          const matchingUserPicksByEventId = allUserPicks.find((userPick) => userPick.eventId === element.eventId);
          if (matchingUserPicksByEventId !== undefined) {
            matchingUserPicksByEventId.picks.forEach((pick, pickIndex) => {
              const matchingUserPicksMatchIndex = this.gamesDataPayload[index].matches.findIndex((match) => match.matchId === pick.matchId);
              if (matchingUserPicksMatchIndex !== -1) {
                this.gamesDataPayload[index].matches[matchingUserPicksMatchIndex].userMadePick = true;
                this.gamesDataPayload[index].matches[matchingUserPicksMatchIndex].home.score = matchingUserPicksByEventId.picks[pickIndex].home.score;
                this.gamesDataPayload[index].matches[matchingUserPicksMatchIndex].away.score = matchingUserPicksByEventId.picks[pickIndex].away.score;
              }
            });
          }
        }
      });
      this.nothingToSubmit = stagesNotAllowedForSubmissionCounter === this.gamesDataPayload.length;
      this.gamesListReady = true;
    },
    isInViewport() {
      if (this.polling !== null) return;
      if (this.$refs['picks-submit'] === undefined) return;
      const rect = this.$refs['picks-submit'].getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.left >= 0
       && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
       && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
      if (isInView === false) return;
      this.polling = setInterval(() => {
        this.submissionLockedTimer -= 1;
        if (this.submissionLockedTimer === 0) {
          this.submissionLocked = false;
          clearInterval(this.polling);
        }
      }, 1000);
    },
    showModalOnEntry() {
      if (sessionStorage.getItem('modalShown') !== null) return;
      this.picksModalOverlay = true;
      sessionStorage.setItem('modalShown', true);
    },
  },
  computed: {
    ...mapGetters([
      'getGamesPageContent',
      'getPicksOverlay',
    ]),
    isOldStage() {
      return this.eventGroupInView !== this.gamesData.defaultEventIndex;
    },
  },
  mounted() {
    this.isInViewport();
    document.addEventListener('scroll', this.isInViewport, false);
    this.showModalOnEntry();
  },
  created() {
    this.committedPicksActive = [...this.gamesData.committedPicks];
    this.prepGamesList();
    this.$moment.locale(this.$store.state.availableGeos[this.$store.state.currentGeo].locale);
  },
  beforeDestroy() {
    if (this.polling !== null) clearInterval(this.polling);
    document.removeEventListener('scroll', this.isInViewport, false);
  },
};
</script>

<style lang="scss">
  .games {
    select {
      width: 100%;
    }
  }
  .stage-game {
    padding: .5rem;
    margin: .5rem 0;
    border-radius: 10px;
    background: $main;
    position: relative;
    &.right {
      background: #cdffcd;
      .match-date {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &.wrong {
      background: #ffc5c5;
    }
  }
  .match-date {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }
  .match-teams {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .5rem 0;
  }
  .match-team {
    flex: 1;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    &:first-child {
      justify-content: flex-end;
    }
  }
  .match-team__flag {
    width: 30px;
    height: 30px;
    background: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    overflow: hidden;
  }
  .match-team__name {
    font-weight: 600;
    font-size: 0.85em;
  }
  .match-team__name + .match-team__flag,
  .match-team__flag + .match-team__name {
    margin-left: .5rem;
  }
  .match-score {
    display: flex;
    width: 64px;
    border: 1px solid rgba(51, 110, 110, 0.6);
    border-radius: 5px;
    margin: 0 .5rem;
    position: relative;
    .input--score {
      color: $font;
    }
    &.disabled {
      pointer-events: none;
      border: 2px solid transparent;
      &::after {
        background: transparent;
      }
      .input--score {
        background: transparent;
        font-weight: 600;
      }
      .score-overlay {
        background: $main;
      }
    }
    &.started-no-pick {
      .score-overlay {
        color: transparent;
        background: transparent;
      }
      .input--score {
        color: transparent;
      }
    }
    &::after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      left: 50%;
      background:rgba(42, 103, 103);
      transform: translateX(-50%);
    }
  }
  .score-overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    color: $font;
    transform: translate(-50%, -50%);
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
  .no-user-pick-made {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
  }
  .match-final-score {
    text-align: center;
    font-size: 12px;
    &.in {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .input--score-wrapper {
    width: 50%;
    flex: none;
    position: relative;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      .score-overlay {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      .score-overlay {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  select.input.input--score {
    padding: .25rem;
    text-align: center;
    background: $secondary;
    text-align-last: center;
    text-align: justify;
  }
  .submit-warning {
    margin: 1rem 0;
    font-size: 0.85em;
  }
  .games-submit-button {
    span {
      color: $font;
      margin-left: .25rem;
    }
    &[disabled] {
      cursor: not-allowed;
      background: $cold-blue-25;
      color: $font;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.disabled {
      cursor: not-allowed;
      color: transparent;
    }
  }
  .lock-icon-button {
    height: 16px;
    margin-right: .5rem;
    fill: $font;
  }
  .games-submit-wrapper {
    position: relative;
  }
  .user-prediction {
    text-align: center;
    margin: .5rem 0;
    font-weight: 600;
    font-size: 12px;
    text-decoration: underline;
    color: $font;
    &.big {
      font-size: 1rem;
    }
  }
  .stage-games {
    transition: 0.2s ease-in-out opacity;
    &.busy {
      opacity: 0.3;
    }
  }
  .stage-nav {
    position: relative;
  }
  .match-availability-info {
    margin: .5rem 0;
    font-size: 0.85em;
    text-align: center;
    span {
      font-weight: 600;
    }
  }
  .games-submit-button {
    font-size: 0.85em;
  }
  .banner-overlay-body img {
    display: block;
    width: 100%;
  }
  .modal-disc {
    font-size: 12px;
    padding: .5rem;
    text-align: center;
  }
  .overlay-iframe {
    display: block;
    max-width: 100%;
  }
</style>
