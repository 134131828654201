<template>
  <div class="hub flex-1">
    <TournamentToggler/>
    <SocialShare/>
    <Timer v-if="eventsData !== null" :startTime="eventsData.eventGroups.competitionStarts"/>
    <div v-if="loading" class="selections-loader">
      <div class="lds-dual-ring"></div>
    </div>
    <div v-if="submissionFeedback" v-html="submissionFeedback" class="submission-feedback-box the-box pad-1 content"></div>
    <BonusQuestions
    @answered="toggleQuestions"
    v-if="eventsData !== null && eventsData.eventBonusQuestions !== false"
    :questions="eventsData.eventBonusQuestions"
    />
    <Games
    @picksSubmitted="toggleFeedback"
    v-if="eventsData !== null && eventsData.eventGroups.events.length > 0 && eventsData.eventBonusQuestions === false"
    :gamesData="eventsData.eventGroups"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import BonusQuestions from '@/components/BonusQuestions.vue';
import Games from '@/components/Games.vue';
import SocialShare from '@/components/SocialShare.vue';
import TournamentToggler from '@/components/TournamentToggler.vue';
import Timer from '@/components/Timer.vue';

export default {
  name: 'Selections',
  data() {
    return {
      eventsData: null,
      loading: true,
      submissionFeedback: '',
    };
  },
  components: {
    BonusQuestions,
    Games,
    SocialShare,
    TournamentToggler,
    Timer,
  },
  methods: {
    ...mapActions([
      'setActiveTournament',
    ]),
    async getTournamentContent() {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const { data } = await this.axios.post(`${process.env.VUE_APP_API}/games/v1/${this.$route.params.tournament}/${this.$route.params.geo}`, { user: this.$store.state.user.email }, config);
        this.setActiveTournament(this.$route.params.tournament);
        this.eventsData = data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    toggleQuestions() {
      if (this.eventsData === null) return;
      this.eventsData.eventBonusQuestions = false;
      this.submissionFeedback = this.$store.state.globalContent.trBonusQThankYou;
      setTimeout(() => {
        this.submissionFeedback = '';
      }, 3500);
    },
    toggleFeedback() {
      this.submissionFeedback = this.$store.state.globalContent.trPicksThankYouMessage;
      setTimeout(() => {
        this.submissionFeedback = '';
      }, 3500);
    },
  },
  created() {
    this.getTournamentContent();
  },
};
</script>

<style lang="scss">
  .hub {
    padding: 1rem;
    z-index: 1;
  }
  .selections-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
  }
  .submission-feedback-box {
    border: 2px solid lightgreen;
    margin: 1rem 0;
    font-size: 0.85em;
  }
</style>
