<template>
  <div v-if="time.diffTime > 0" class="competition-timer the-box pad-1">
    <div class="competition-timer-label">
      {{ $store.state.globalContent.trTimerLabel }}
    </div>
    <div class="countdown-timer">
      {{ time.days }} {{ $store.state.globalContent.trDays }}, {{ time.hours }} {{ $store.state.globalContent.trHours }}, {{ time.minutes }} {{ $store.state.globalContent.trMinutes }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Timer',
  props: {
    startTime: Number,
  },
  data() {
    return {
      time: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        diffTime: 0,
      },
      polling: null,
    };
  },
  methods: {
    timeCalc() {
      const startTime = this.$moment(this.startTime * 1000);
      const current = this.$moment();
      // Get time difference
      this.time.diffTime = startTime.diff(current);
      if (this.time.diffTime <= 0) return;
      // Calc duration
      let duration = this.$moment.duration(this.time.diffTime);
      // Set time
      this.time.days = duration.days();
      this.time.hours = duration.hours();
      this.time.minutes = duration.minutes();
      this.time.seconds = duration.seconds();
      // Start countdown
      this.polling = setInterval(() => {
        this.time.diffTime -= 1000;
        duration = this.$moment.duration(this.time.diffTime);
        this.time.days = duration.days();
        this.time.hours = duration.hours();
        this.time.minutes = duration.minutes();
        this.time.seconds = duration.seconds();
      }, 1000);
    },
  },
  mounted() {
    this.timeCalc();
  },
  computed: {
    timeReached() {
      return this.time.diffTime <= 0;
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
};
</script>

<style lang="scss">
  .competition-timer {
    margin: 1rem 0;
    font-size: 0.85em;
  }
  .countdown-timer {
    font-weight: 600;
  }
</style>
