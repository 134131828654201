<template>
  <div class="bonus-questions">
    <div class="bonus-questions-intro the-box pad-1 content" v-html="getBonusQuestionsContent.trBonusQBox"></div>
    <form v-if="answers.length > 0" @submit.prevent="submitBonusQuestionsAnswers">
      <div v-for="(question, index) in questions.questions" :key="index" class="bonus-q-row the-box">
        <div class="box-label pattern-bg">{{ question.question }}<span class="bonus-points">50 {{ getBonusQuestionsContent.trPoints }}</span></div>
        <div class="bonus-question-choices pad-1">
          <div v-for ="n in question.allowedChoices" :key="`${index}-${n}`" class="bonus-question-choices__single">
            <ChevronIcon class="chevron-in-select"/>
            <select class="input input--select" v-model="answers[index][n - 1]">
              <option disabled :value="getBonusQuestionsContent.trSelectOne">{{ getBonusQuestionsContent.trSelectOne }}</option>
              <option v-for="team in questions.teams" :key="team.teamId" :value="team.teamId">{{ team.teamName }}</option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="feedback" class="bonus-q-feedback">{{ feedback }}</div>
      <div class="bonus-q-submission-button">
        <div v-if="submitting" class="lds-dual-ring button-loading"></div>
        <button class="button button--main full" :disabled="submitting" type="submit">{{ getBonusQuestionsContent.trSubmitBonusQ }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChevronIcon from '@/assets/images/chevron.svg';

export default {
  name: 'BonusQuestions',
  props: ['questions'],
  components: {
    ChevronIcon,
  },
  data() {
    return {
      answers: [],
      feedback: '',
      allowPayload: false,
      submitting: false,
    };
  },
  methods: {
    submitBonusQuestionsAnswers() {
      if (this.submitting) return;
      const payload = {
        tournament: this.$store.state.activeTournament,
        competitionId: this.questions.competitionId,
        user: this.$store.state.user.email,
        userid: this.$store.state.user.id,
        type: 'bonus',
        id: this.questions.bonusQuestionsId,
        geo: this.$route.params.geo,
      };

      // Check if a bonus questions was unanswered - the answers are numbers (teamIds)
      if (this.answers.findIndex((arr) => arr.find((answer) => typeof answer === 'string')) !== -1) {
        this.feedback = this.getBonusQuestionsContent.trAnswersFeedback;
        setTimeout(() => {
          this.feedback = '';
        }, 2000);
        return;
      }

      this.allowPayload = true;

      for (let index = 0; index < this.answers.length; index += 1) {
        const uniqueAnswerIds = new Set(this.answers[index]);
        if (uniqueAnswerIds.size !== this.answers[index].length) {
          this.feedback = `${this.getBonusQuestionsContent.trUniqueAnswers} - ${this.getBonusQuestionsContent.trQuestion} ${index + 1}`;
          setTimeout(() => {
            this.feedback = '';
          }, 2000);
          this.allowPayload = false;
          break;
        }
      }

      if (!this.allowPayload) return;

      payload.answers = this.answers;

      this.savePicks(payload);
    },
    async savePicks(payload) {
      try {
        this.submitting = true;
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const { data } = await this.axios.post(`${process.env.VUE_APP_API}/picks/v1/save`, payload, config);
        this.submitting = false;
        document.getElementById('app').scrollIntoView();
        console.log(data);
        if (data === 1) this.$emit('answered');
      } catch (error) {
        console.log(error);
      }
    },
    answersPlaceholder(newValue) {
      // Build out the structure of the array that will hold user bonus question selections
      const allowedChoicesArray = newValue.questions.map((question) => question.allowedChoices);

      allowedChoicesArray.forEach((element) => {
        this.answers.push(new Array(element).fill(this.getBonusQuestionsContent.trSelectOne));
      });
    },
  },
  computed: {
    ...mapGetters([
      'getBonusQuestionsContent',
    ]),
  },
  created() {
    this.answersPlaceholder(this.questions);
  },
};
</script>

<style lang="scss">
  .bonus-questions-intro {
    font-size: .85em;
  }
  .bonus-questions {
    margin: 1rem 0;
  }
  .bonus-q-row {
    margin: 1rem 0;
  }
  .bonus-question-choices {
    select {
      width: 100%;
    }
  }
  .bonus-question-choices__single {
    position: relative;
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
  .bonus-points {
    position: absolute;
    right: 1rem;
    font-weight: 400;
    font-size: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .bonus-q-feedback {
    text-align: center;
    font-size: 0.85em;
    font-weight: 600;
    margin-bottom: .5rem;
  }
  .bonus-q-submission-button {
    position: relative;
    .button[disabled] {
      cursor: not-allowed;
      color: transparent;
    }
  }
</style>
